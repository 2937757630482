






















import Vue from "vue";
import CenterContent from "@/components/templates/CenterContent.vue";
import tenantService from "@/services/tenantService";
export default Vue.extend({
  name: "template_main",
  components: {
    CenterContent
  },
  computed: {
    bgLight() {
      return tenantService.requireBackground("room-waiting.jpg");
    },
    bgDark() {
      return tenantService.requireBackground("room-waiting-dark.jpg");
    }
  }
});

// :light-bg="require('@/assets/img/backgrounds/room-waiting.jpg')"
